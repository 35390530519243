body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
h3{
  margin: 0px;
}

.main_conatiner {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.main_header {
  width: 80%;
  display: flex;
  justify-content: space-between;
}
.header_ul {
  display: flex;
  justify-content: space-evenly;
  list-style: none;
}
.left_header {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #7b29c2;
  font-weight: 800;
}
.right_header {
  /* display: flex; */
  width: 50%;
}
.header_ul {
  display: flex;
  justify-content: space-evenly;
  list-style: none;
}
.mid-section {
  width: 80%;
  display: flex;
  justify-content: space-around;
  margin: 15px 0;
  align-items: center;
  min-height: 500px;
}
.no-item-selected {
  font-size: 17px;
  font-weight: 600;
}

.mid_main_wrap {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #7b29c2;
}
.check_box_section {
  width: 40%;
  background: #fff;
  height: 400px;
  border-radius: 4px;
  overflow-y: scroll;
}
.selected_section {
  width: 40%;
  background: #fff;
  height: 400px;
  border-radius: 4px;
  overflow-y: scroll;
}
.ul_right li {
  list-style: none;
  background: #e4e4e4;
  padding: 10px;
  width: 90%;
  border-radius: 2px;
  margin: 11px 6px;
}
.right_section {
  padding: 0 20px;
}
.checkbox_individual_wraper {
  padding: 10px;
}
.remove_item {
  position: relative;
}
i.fa.fa-remove {
  font-size: 24px;
  position: absolute;
  margin-left: 14px;
  bottom: -3px;
  color: gray;
}

@media screen and (max-width: 1024px) {
  .movie_card_container {
      background: gray;
      align-items: center;
      justify-content: center;
      display: flex;
      flex-direction: column;
      padding: 7px;
      margin: 10px;
      border-radius: 2px;
      flex: 0 0 45%;
  }
}

/* media query for mobile view 700px*/


@media screen and (max-width: 700px) {
  .right_header {
      display: flex;
      width: 100%;
      /* justify-content: center; */
  }
  .header_ul {
      display: flex;
      justify-content: center;
      list-style: none;
      padding: 0;
      flex-wrap: wrap;
      /* flex-direction: column; */
  }
  .header_ul li {
      width: 50%;
      text-align: center;
  }
  .main_header {
      width: 80%;
      display: flex;
      justify-content: space-between;
      flex-direction: column;
  }
  .check_box_section {
    width: 100%;
    background: #fff;
    height: 400px;
    border-radius: 4px;
    overflow-y: scroll;
}
.selected_section {
  width: 100%;
  background: #fff;
  height: 400px;
  border-radius: 4px;
  overflow-y: scroll;
  margin-top: 20px;
}
.mid-section {
  width: 80%;
  display: flex;
  justify-content: space-around;
  margin: 15px 0;
  align-items: center;
  flex-direction: column;
  min-height: 500px;
}
}
